import React from 'react'
import fundService from '../../services/fund/fund-service';
import { useQuery } from '@tanstack/react-query';
import { Form } from 'react-bootstrap';

function FinancialYearSelectField(props) {
    const { isLoading, isError, error, data:items, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["finance-years"],
      queryFn: () => fundService.getFinancialYear(),
      keepPreviousData: true
    });
  return (
    <>
    {isError ? (<>Error Occurred</>) :(<>
      <Form.Select aria-label="select Finance Year" {...props}>
        <option>None</option>
        {items?.data.results.map((item, i) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select></>)}
    </>
  );
}

export default FinancialYearSelectField