import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import fundService from '../../services/fund/fund-service';
import AlertContext from '../widgets/alertPopup/AlertContext';
import subscriptionService from '../../services/subscription/subscription-service';
import { formatHttpErrorMessage } from '../common/helper_functions';
import productService from '../../services/product/product-service';
import schemeService from '../../services/scheme/scheme-service';

function OfferForm(props) {
    const {setAlert} = useContext(AlertContext);
    const [message, setMessage] = useState();
    const [financialYear, setFinancialYear] = useState([]);
    const [memberFees, setMemberFees] = useState([])
    const [subFees, setSubFees] = useState([]);
    const [regFees, setRegFees] = useState([]);
    const [otherFees, setOtherFees] = useState([]);
    const [products, setProducts] = useState([]);
    const [families, setFamilies] = useState([]);
    const [formData, setFormData] = useState({
            "guid": "",
            "fyear":"",
            "start_date": null,
            "end_date": null,
            "members": 1,
            "post_date": null,
            "reg_fee_id": null,
            "member_fee_id": null,
            "subscription_fee_id": null,
            "other_fee_id": null,
            "subscheme": null,
            "family": props.family,
            "product": null
        })

    useEffect(() => {
        loadFinancialYear()
        loadFees()
        loadProducts()
        loadFamilies({guid: props.family})
    },[props.family])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const loadFinancialYear = async () => {
              await fundService.getFinancialYear().then(response => {
                 setFinancialYear(response.data.results)
              }).catch(error => {
                let msg = formatHttpErrorMessage(error)
                setAlert(msg, "danger");
              })
    }

       const loadProducts = () => {
            productService.getAllProducts().then(
                (payload) => {
                    setProducts(payload);
                },
                (error) => {
                    const resMessage = formatHttpErrorMessage(error);
                    setAlert(resMessage, "danger");
                }
            );
        };

    const generateUniqueId = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
    };

     const loadFees = () => {
            fundService.getAllFees({category:'Member'}).then((response) => setMemberFees(response));
            fundService.getAllFees({category:'Registration'}).then((response) => setRegFees(response));
            fundService.getAllFees({category:'Subscription'}).then((response) => setSubFees(response));
            fundService.getAllFees({category:'Other'}).then((response) => setOtherFees(response));
                    
    }

     const loadFamilies = (items={}) => {
            schemeService.getAllFamilies(items).then(
                (payload) => {
                    setFamilies(payload.data.results);
                },
            ).catch( (error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
            });
        };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        console.log(formData)
        formData.guid = generateUniqueId()
           
        subscriptionService.addOffer(formData).then(
                (result) => {
                     setFormData({})
                     setAlert("Offer Successfully created!", "success");
                     props.onHide();
                }
                
            ).catch((error) => {
                const resMessage = formatHttpErrorMessage(error);
                setAlert(resMessage, "danger");
                setMessage(resMessage)
        });
        
    };


  return (
    <>
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" >
                Offer Form
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Form
                    onSubmit={handleSubmit}
                            >
                                {message && <Alert variant='danger'>{message}</Alert>}
                                <Row className="g-3">
                                <Col md="12">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Family"
                                        >
                                            <Form.Select
                                                aria-label="select Family"
                                                name="family"
                                                value={formData.family}
                                                onChange={handleChange}
                                                required
                                                disabled={props.family !==undefined}
                                            >
                                                <option value="">None</option>
                                                {families.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.guid}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select family.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Offer start Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                name="start_date"
                                                value={formData.start_date}
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Offer end Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                name="end_date"
                                                value={formData.end_date}
                                                onChange={handleChange}
                                                min={new Date().toISOString().split('T')[0]} 
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Posting Date"
                                            className=""
                                        >
                                            <Form.Control
                                                type="date"
                                                name="post_date"
                                                value={formData.post_date}
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid date.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Product"
                                        >
                                            <Form.Select
                                                aria-label="select Product"
                                                name="product"
                                                value={formData.product}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">None</option>
                                                {products.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.id}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select product.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Financial Year"
                                        >
                                            <Form.Select
                                                aria-label="select Financial Year"
                                                name="fyear"
                                                value={formData.fyear}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">None</option>
                                                {financialYear.map((item) => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Financial Year.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                  

                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Registration Fees"
                                        >
                                            <Form.Select
                                                aria-label="select Registration Fees"
                                                name="reg_fee_id"
                                                value={formData.reg_fee_id}
                                                onChange={handleChange}
                                                
                                            >
                                                <option value="">None</option>
                                                {regFees.map((item) => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Registration Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Subscription Fees"
                                        >
                                            <Form.Select
                                                aria-label="select Subscription Fees"
                                                name="subscription_fee_id"
                                                value={formData.subscription_fee_id}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option>None</option>
                                                {subFees.map((item, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Subscription Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Member Fees"
                                        >
                                            <Form.Select
                                                aria-label="select Member Fees"
                                                name="member_fee_id"
                                                value={formData.member_fee_id}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option>None</option>
                                                {memberFees.map((item, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Subscription Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="4">
                                        <FloatingLabel
                                            controlId="otherfeeSelect"
                                            label="Select Other Fee"
                                        >
                                            <Form.Select
                                                aria-label="select Other Fees"
                                                name="other_fee_id"
                                                value={formData.other_fee_id}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value={0}>None</option>
                                                {otherFees.map((item, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={item.id}
                                                    >
                                                        {item.name}({item.amount})
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Other Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                  
                                    
                                    <Col xs="12" className='float-end'>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
            </Modal.Body>
    </Modal>
    </>
  )
}

export default OfferForm