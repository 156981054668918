import { useContext, useEffect, useState } from "react";
import {
    Button,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import productService from "../../services/product/product-service";
import subscriptionService from "../../services/subscription/subscription-service";
import fundService from "../../services/fund/fund-service";
import AlertContext from "../widgets/alertPopup/AlertContext";
import { formatHttpErrorMessage } from "../common/helper_functions";
import AuthDetailContext from "../common/AuthDetailContext";


const Offer = ({ currentUser, match }) => {
    let {setAlert} = useContext(AlertContext);
    const { offerId, edit } = match.params;
    const [offer, setOffer] = useState(null);
    const [isEdit, setEdit] = useState(false);
    const [products, setProducts] = useState([]);
    const [ToastMessage, setToastMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState("light");
    const [showForm, setShowForm] = useState(false);
    const [offerStartDate, setOfferStartDate] = useState("");
    const [offerEndDate, setOfferEndDate] = useState("");
    const [offerFamily, setOfferFamily] = useState("");
    const [offerProduct, setOfferProduct] = useState("");
    const [offerMembers, setOfferMembers] = useState(1);
    const [offerPostDate, setOfferPostDate] = useState("");
    const [offerGuid, setOfferGuid] = useState("");
    const [offerRegFees, setOfferRegFees] = useState();
    const [offerSubFees, setOfferSubFees] = useState();
    const [offerMemberFees, setOfferMemberFees] = useState();
    const [offerOtherFees, setOfferOtherFees] = useState();
    const [offerFyear, setOfferFyear] = useState();
    const [memberFees, setMemberFees] = useState([])
    const [subFees, setSubFees] = useState([]);
    const [regFees, setRegFees] = useState([]);
    const [otherFees, setOtherFees] = useState([]);
    const [financialYear, setFinancialYear] = useState([]);
    const [validated, setValidated] = useState(false);
    const history = useHistory();

    useEffect(() => {
        checkForEditInUrl();
        loadProducts();
        loadFees();
        loadFinancialYear();
        loadOffer(offerId);
    }, []);

    const toggleEdit = () => {
        setEdit(!isEdit);
    };

    const loadOffer = (offerId) => {
        subscriptionService.getOffer(offerId).then(
            (result) => {
                console.log("result", result);
                setOffer(result);
                setOfferStartDate(result.start_date);
                setOfferEndDate(result.end_date);
                setOfferFamily(result.family);
                setOfferProduct(result.product);
                setOfferPostDate(result.post_date);
                setOfferGuid(result.guid);
                setOfferMemberFees(result.member_fee_id);
                setOfferSubFees(result.subscription_fee_id);
                setOfferRegFees(result.reg_fee_id);
                setOfferOtherFees(result.other_fee_id);
                setOfferMembers(result.members)
                setOfferFyear(result.fyear)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };

    const loadProducts = () => {
        productService.getAllProducts().then(
            (payload) => {
                setProducts(payload);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setToastVariant("danger");
                setShowToast(true);
                setToastMessage(resMessage);
            }
        );
    };

    const loadFees = () => {
        fundService.getAllFees({category:'Member'}).then((response) => setMemberFees(response));
        fundService.getAllFees({category:'Registration'}).then((response) => setRegFees(response));
        fundService.getAllFees({category:'Subscription'}).then((response) => setSubFees(response));
        fundService.getAllFees({category:'Other'}).then((response) =>{
         
            setOtherFees(response);
        } );
        
    }

      const loadFinancialYear = async () => {
                  await fundService.getFinancialYear().then(response => {
                     setFinancialYear(response.data.results)
                  }).catch(error => {
                    let msg = formatHttpErrorMessage(error)
                    setAlert(msg, "danger");
                  })
        }

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result == true) {
            subscriptionService.deleteOffer(id).then(
                () => {
                    setAlert("Successfully Deleted scheme");
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                
                    setAlert(resMessage, "danger");
                }
            );
        } else {
        }
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let user = localStorage.getItem("user"); 
            let payload = {
                start_date: offerStartDate,
                end_date: offerEndDate,
                post_date: offerPostDate,
                product: offerProduct,
                family: offerFamily,
                guid: offerGuid,
                members:offerMembers,
                reg_fee_id: offerRegFees,
                member_fee_id: offerMemberFees,
                subscription_fee_id: offerSubFees,
                other_fee_id: offerOtherFees,
                fyear:offerFyear,
                owner: JSON.parse(user).id,
            };
            subscriptionService.updateOffer(payload, offerId).then(
                (result) => {
                    setToastVariant("light");

                    loadOffer(offerId);
                    setAlert("Successfully updated offer", "success");
                    toggleEdit();
                    setValidated(false);
                },
                (error) => {
                    console.log(error);
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                  
                    setAlert(JSON.stringify(resMessage), "danger");
                }
            );
        }
    };

    const checkForEditInUrl = () => {
        if (window.location.href.indexOf("edit") > -1) {
            setEdit(true);
        }
    };

    return (
        <Row className="g-3">
            <Col xs="12" className="pt-3">
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/families"> Families </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/offers"> Offers </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {offer ? offer.id : ""}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body className="bg-white d-flex justify-content-between align-content-center">
                        <h4 className="m-0">
                            Offer: <strong>{offer ? offer.id : ""}</strong>
                        </h4>
                        <div>
                            <Button
                                variant="outline-info"
                                size="sm"
                                onClick={history.goBack}
                            >
                                <FiArrowLeft></FiArrowLeft>
                            </Button>
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={toggleEdit}
                            >
                                Edit
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body>
                        {!offer && (
                            <Spinner animation="border" variant="warning" />
                        )}
                        {offer && (
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={handleSubmit}
                                className="row g-3"
                            >
                                <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Financial Year"
                                        >
                                            <Form.Select
                                                aria-label="select Financial Year"
                                                disabled={!isEdit && currentUser['is_superuser']}
                                                value={offerFyear}
                                                onChange={(e) =>
                                                    setOfferFyear(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">None</option>
                                                {financialYear.map((item) => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Financial Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                     <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Offer Start Date"
                                        >
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="date"
                                        value={offerStartDate}
                                        onChange={(e) =>
                                            setOfferStartDate(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid date.
                                    </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                     <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Offer End Date"
                                        >
                                    <Form.Control
                                        required
                                        readOnly={!isEdit}
                                        type="date"
                                        value={offerEndDate}
                                        onChange={(e) =>
                                            setOfferEndDate(e.target.value)
                                        }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid date.
                                    </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="exampleForm.ControlInput1"
                                >
                                     <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Offer Post Date"
                                        >
                                    
                                    <Form.Control
                                        readOnly={!isEdit}
                                        type="date"
                                        value={offerPostDate}
                                        onChange={(e) =>
                                            setOfferPostDate(e.target.value)
                                        }
                                    />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group
                                    className="col-md-3"
                                    controlId="parentofferId"
                                >
                                    <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Product"
                                        >
                                    <Form.Select
                                        aria-label="select Product"
                                        disabled={!isEdit}
                                        value={offerProduct}
                                        onChange={(e) =>
                                            setOfferProduct(e.target.value)
                                        }
                                        required
                                    >
                                        <option value="">None</option>
                                        {products.map((product) => (
                                            <option
                                                key={product.id}
                                                value={product.id}
                                            >
                                                {product.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select product.
                                    </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>
                                
                                <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Registration Fees"
                                        >
                                            <Form.Select
                                                aria-label="select Registration Fees"
                                                disabled={!isEdit}
                                                value={offerRegFees}
                                                onChange={(e) =>
                                                    setOfferRegFees(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">None</option>
                                                {regFees && regFees.map((item) => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.name}({item.amount})
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Registration Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Subscription Fees"
                                        >
                                            <Form.Select
                                                aria-label="select Subscription Fees"
                                                disabled={!isEdit}
                                                value={offerSubFees}
                                                onChange={(e) =>
                                                    setOfferSubFees(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="0">None</option>
                                                {subFees && subFees.map((item, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={item.id}
                                                    >
                                                        {item.name}({item.amount})
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Subscription Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                    <Col md="3">
                                        <FloatingLabel
                                            controlId="floatingSelect"
                                            label="Select Member Fees"
                                        >
                                            <Form.Select
                                                aria-label="select Member Fees"
                                                disabled={!isEdit}
                                                value={offerMemberFees}
                                                onChange={(e) =>
                                                    setOfferMemberFees(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="0">None</option>
                                                {memberFees && memberFees.map((item, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={item.id}
                                                    >
                                                        {item.name}({item.base_amount})
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Subscription Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="3">
                                       
                                        <FloatingLabel
                                            controlId="otherfeeSelect"
                                            label="Select Other Fee"
                                        >

                                            <Form.Select
                                                aria-label="select Other Fees"
                                                disabled={!isEdit}
                                                value={offerOtherFees}
                                                onChange={(e) =>
                                                    setOfferOtherFees(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="0">None</option>
                                                {otherFees && otherFees.map((item, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={item.id}
                                                    >
                                                        {item.name}({item.amount})
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Other Fees.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Col>

                                {isEdit && (
                                    <div className="col-12">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            size="sm"
                                        >
                                            Update
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12">
                <Card>
                    <Card.Body>
                        <ListGroup>
                            <ListGroupItem action>
                                <Link
                                    to={
                                        offer
                                            ? `/payments/${offer.guid}`
                                            : "/payments"
                                    }
                                    style={{
                                        textDecoration: "none",
                                    }}
                                >
                                    Payments
                                </Link>
                            </ListGroupItem>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Col>
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={6000}
                autohide
                bg={toastVariant}
            >
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Infomation</strong>
                    <small>now</small>
                </Toast.Header>
                <Toast.Body>{ToastMessage}</Toast.Body>
            </Toast>
        </Row>
    );
};
export default Offer;
