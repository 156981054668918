import { useContext, useEffect, useState } from "react";
import {
    Button,
    Spinner,
    Form,
    FloatingLabel,
    ButtonGroup,
    Card,
    Breadcrumb,
    Toast,
    Table,
    Row,
    Col,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import productService from "../../services/product/product-service";
import schemeService from "../../services/scheme/scheme-service";
import subscriptionService from "../../services/subscription/subscription-service";
import PaymentForm from "./PaymentForm";
import BadgeWidget from "../widgets/BadgeWidget";
import { EyeFill, Trash } from "react-bootstrap-icons";
import moment from "moment";
import { formatHttpErrorMessage, getFormValues, isExpiredDate, formatShortDate } from "../common/helper_functions";
import AuthDetailContext from "../common/AuthDetailContext";
import AlertContext from "../widgets/alertPopup/AlertContext";
import Pager from "../common/Pager";

const Payments = (props) => {
    const currentUser = useContext(AuthDetailContext);
    const {setAlert} = useContext(AlertContext);
    const { offerId, familyGuid } = props.match.params;
    const [products, setProducts] = useState([]);
    const [offers, setOffers] = useState([]);
    const [payments, setPayments] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(0.0);
    const [paymentName, setPaymentName] = useState("");
    const [paymentNarration, setPaymentNarration] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("Cash");
    const [paymentPostDate, setPaymentPostDate] = useState("");
    const [paymentReference, setPaymentReference] = useState("");
    const [paymentOffer, setPaymentOffer] = useState("");
    const [paymentFundSource,setPaymentFundSource] = useState("Pocket")
    const [validated, setValidated] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [subschemes, setSubschemes] = useState([]);
    const [schemes, setSchemes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [numberOfPages,setNumberOfPages] = useState(1);
    const [activePage,setActivePage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(50);
    const [activeParams, setActiveParams] = useState({})
    const [summary, setSummary] = useState({})
    const [showAdSearch, setShowAdSearch] = useState(false)
    const history = useHistory();

    useEffect(() => {
        loadProducts();
        loadOffers();
        loadPayments();
        fetchSummary()
        getSearchSchemes();
        if (offerId !== undefined) {
            setPaymentOffer(offerId);
        }else{

        }
        
    }, []);
    const toggleForm = () => {
        setPaymentAmount(0.0);
        setPaymentMethod("Cash");
        setPaymentName("");
        // setPaymentOffer("")
        setShowForm(!showForm);
    };

   
    const getSearchSchemes = async () => {
        setSubschemes([]);
        await schemeService.getAllSchemes().then((response) => setSchemes(response.results));
    }

    const getSubSchemes = async (scheme) => {
        setSubschemes([]);
        await schemeService.searchSubSchemes({scheme:scheme}).then((response)=> setSubschemes(response.results));
    }

    const generateUniqueId = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
                c ^
                (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
        );
    };
    const loadProducts = () => {
        productService.getAllProducts().then(
            (payload) => {
                setProducts(payload);
                setLoading(false);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setAlert(resMessage,"danger");
                setLoading(false);
            }
        );
    };
  

    const loadOffers = () => {
        
        if (familyGuid == undefined){
            subscriptionService.getAllOffers().then(
                (payload) => {
                    setOffers(payload);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
    
                    setAlert("Failed to Load Offers","danger");
                    setLoading(false);
                }
            );
        }else{
            subscriptionService.filterOffers(familyGuid).then(
                (payload) => {
                    setOffers(payload);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    setAlert("Failed to Load Offers","danger");
                    setLoading(false);
                }
            );
        }
       
    };

    const loadPayments = (nextPage=1, formParams={}) => {
        const offset = (nextPage > 1) ? (nextPage-1)*entriesPerPage : '';
		let params = {offset:offset,offer:offerId, ...formParams}
       
		if(Object.keys(formParams).length > 0){
			setActiveParams(formParams);
			params = {offset:offset,  ...formParams}
		}else{
			params = {offset:offset, ...activeParams}
		}
        console.log(familyGuid);
        if(familyGuid){
            params={offer__family:familyGuid}
        }
        if(offerId){
            params={offer:offerId}
        }
       
       
        subscriptionService.getAllPayments(params).then(
                (payload) => {
                    setPayments(payload.data.results);
                    const pages = (payload.data.count > 0) ? Math.ceil(payload.data.count/entriesPerPage) : 0
                    setNumberOfPages(pages);               
                    setActivePage(nextPage);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    setAlert(resMessage,"danger");
                    setLoading(false);
                }
            );
           
        
    };

    const handleDelete = (event, id) => {
        var result = window.confirm("are you sure you want to delete? ");
        if (result === true) {
            subscriptionService.deletePayment(id).then(
                (response) => {
                    setAlert("Successfully Deleted Payment");
                    loadPayments();
                }
            ).catch(error => {
                const resMessage = formatHttpErrorMessage(error)
                    setAlert(resMessage,"danger" );
                    setLoading(false);
            });
        } 
    };

    const fetchSummary = (params) => {
        subscriptionService.getFilteredPaymentSummary(params).then(
            (payload) => {
                setSummary(payload);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setAlert(resMessage,"danger");
                setLoading(false);
            }
        );
    }
    const showPayment = (e, paymentId) => {
        console.log("paymentId", paymentId);
        const paymentEdit = payments.find(({ id }) => id === paymentId);
        if (paymentEdit !== undefined) {
            setPaymentAmount(paymentEdit.amount);
            setPaymentMethod(paymentEdit.method);
            setPaymentName(paymentEdit.name);
            setPaymentNarration(paymentEdit.narration);
            setPaymentOffer(paymentEdit.offer);
            setPaymentReference(paymentEdit.reference);
            setPaymentPostDate(paymentEdit.post_date);
        }
        setShowForm(true);
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        if (form.checkValidity() === false) {
            //event.preventDefault();
            event.stopPropagation();
            invalid = true;
        }

        setValidated(true);

        if (invalid == false) {
            let payload = {
                post_date: paymentPostDate,
                reference: paymentReference,
                amount: paymentAmount,
                name: paymentName,
                narration: paymentNarration,
                method: paymentMethod,
                offer: paymentOffer,
                guid: generateUniqueId(),
                fund_source: paymentFundSource,
            };
            subscriptionService.addPayment(payload).then(
                (result) => {
                  
                    setAlert("Successfully made payment");
                    setPaymentAmount(0.0);
                    setPaymentMethod("");
                    setPaymentName("");
                    setPaymentOffer("");
                    setPaymentNarration("");
                    form.reset();
                    setShowForm(false);
                    loadPayments();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                   
                    setAlert(resMessage,"danger");
                    setLoading(false);
                }
            );
        }
    };

    const handleSearch = (event) => {
        const form = event.currentTarget;
        let invalid = false;
        event.preventDefault();
        var nextPage=1;
        setLoading(true);
        setPayments([]);
        const params = getFormValues(event);
        loadPayments(1,params);
        fetchSummary(params)
        setLoading(false);
    }

    const closePaymentForm = () =>{
        loadPayments();
        setShowPaymentForm(false);
    }

    const goToPage = (event, page) => {
        loadPayments(page);
    }

    return (
        <Row className="g-3">
            {!props.basic && <>
            <Col xs="12" className="pt-3">
                <h4 className="m-0">Payments </h4>
                <Breadcrumb className="border-bottom mb-3">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/products"> Products</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Payments</Breadcrumb.Item>
                </Breadcrumb>
            </Col>
           
            <Col xs="12">
                <Card>
                    
                    <Card.Body className="bg-white">
                    <Form
                                validated={validated}
                                onSubmit={handleSearch}
                            >
                            <Row className="mb-3">
                                <Col md={3}>
                                <Form.Control
                                                type="text"
                                                placeholder="Receipt"
                                                name='reference'
                                            />
                                </Col>
                                <Col md={3}>
                                <Form.Select
                                                aria-label="Product"
                                                name='product'
                                                
                                            >
                                                <option value="">Choose Product</option>
                                                {products.map((product) => (
                                                    <option
                                                        key={product.id}
                                                        value={product.id}
                                                    >
                                                        {product.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                </Col>
                                <Col md={3}>
                                <Form.Select
                                                aria-label="select Scheme"
                                                name='offer__family__subscheme__scheme'
                                                onChange={(e) => getSubSchemes(e.target.value)}
                                            >
                                                <option value="">Choose Scheme</option>
                                                {schemes.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.id}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                </Col>
                                <Col md={3}>
                                <Form.Select
                                                aria-label="select Sub Scheme"
                                                name='offer__family__subscheme'
                                            >
                                                <option value="">{subschemes.length <=0 ? 'No Subscheme':'Choose Sub-Scheme'}</option>
                                                {subschemes.map((parent) => (
                                                    <option
                                                        key={parent.id}
                                                        value={parent.id}
                                                    >
                                                        {parent.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                </Col>
                               
                            </Row>
                            <Row className="mb-3" hidden={!showAdSearch}>
                                <Col md={3}>
                                <Form.Group className="mb-3">
                                <Form.Label>Created Min</Form.Label>
                                    <Form.Control
                                                type="date"
                                                placeholder="Date"
                                                name='created_min'
                                            /> 
                                </Form.Group>
                                </Col>
                                <Col md={3}>
                                <Form.Group className="mb-3">
                                <Form.Label>Created Max</Form.Label>
                                    <Form.Control
                                                type="date"
                                                placeholder="Date"
                                                name='created_max'
                                            /> 
                                </Form.Group>
                                </Col>
                                <Col md={3}>
                                <Form.Group className="mb-3">
                                <Form.Label>Post Date</Form.Label>
                                    <Form.Control
                                                type="date"
                                                placeholder="Date"
                                                name='post_date'
                                            /> 
                                </Form.Group>
                                </Col>
                               
                            </Row>
                            <Row>
                                <Col md={3}>
                                <Button
                                            variant="link"
                                            size="md"
                                            onClick={() => setShowAdSearch(!showAdSearch)} 
                                        >
                                            {showAdSearch ? "Close":"Advanced"}
                                        </Button>&nbsp;&nbsp;
                                <Button
                                            variant="primary"
                                            type="submit"
                                            size="md"
                                        >
                                            Search
                                        </Button>
                                </Col>
                            
                            </Row>
                    </Form>
                    </Card.Body>
                </Card>
            </Col> </>}
            {!props.basic && summary && <Col md={12}>
                 <Row className="g-2">
                    <Col md={4}>
                        <Card>
                            <Card.Body>
                                <h6>Total</h6>
                                <h4>{summary.amount ? summary.amount.toLocaleString() : "0"}</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                    <Card>
                            <Card.Body>
                                <h6>No. Payments</h6>
                                <h4>{summary.total}</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                    <Card>
                            <Card.Body>
                                <h6>No. Offers</h6>
                                <h4>{summary.offers}</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>                                   
            </Col>}
            <Col xs="12">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={12} className="text-right mb-2">
                            <Button
                                size="sm"
                                variant="outline-info"
                                onClick={history.goBack}
                            >
                                <FiArrowLeft></FiArrowLeft>
                            </Button>
                            {currentUser && (currentUser['is_superuser'] || currentUser['is_staff'] ===false) && (<>
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={() => setShowPaymentForm(true)}
                              
                            >
                                Add Payment
                            </Button>
                            <PaymentForm show={showPaymentForm}
									onHide={() => setShowPaymentForm(false)} onClose={() => closePaymentForm()} familyGuid={familyGuid} />
                                    </>)}
                            </Col>
                            <Col md={12}>
                            
                        <div className="table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Family</th>
                                        <th>Amount</th>
                                        <th>Paid By</th>
                                       
                                        <th>Naration</th>
                                        <th>Type</th>
                                        <th>Date Paid</th>
                                        <th>Receipt</th>
                                        <th>Status</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && (
                                        <Spinner
                                            animation="border"
                                            variant="warning"
                                        />
                                    )}
                                    {payments.map((payment, index) => (
                                        <tr key={index}>
                                            <td>{index + 1 + (entriesPerPage * (activePage - 1))}</td>
                                            <td>{payment.family}</td>
                                            <td>{parseInt(payment.amount).toLocaleString('en-US', {minimumFractionDigits: 0})}</td>
                                            <td>{payment.name}</td>
                                            
                                            <td>{payment.narration}</td>
                                            <td>{payment.method}</td>
                                            <td>{payment.post_date}</td>
                                            
                                            <td>{payment.reference}</td>
                                            <td><BadgeWidget text={payment.status} /></td>
                                            <td>{formatShortDate(payment.created)}</td>
                                            <td>
                                            <a href="#"  onClick={(e) => {
                                                            handleDelete(
                                                                e,
                                                                payment.id
                                                            );
                                                        }}
                                                    >
                                                       <Trash />
                                                    </a>
                                            </td>
                                         
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Pager
                  numberOfPages={numberOfPages}
                  activePage={activePage}
                  goToPage={goToPage}
                />
                        </div>
                         </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            
        </Row>
    );
};
export default Payments;
